import { Box, Heading } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';

const BaseChart: React.FC<{
  heading: string,
  children: ReactElement;
}> = ({ heading, children }) => {
  return (
    <Box
      p={4}
      bg="white"
      shadow="md"
      borderRadius="lg"
      borderWidth={1}
      borderColor="gray.200"
    >
      <Heading size="md" mb={4}>{heading}</Heading>
      <ResponsiveContainer width="100%" height={400}>
        {children}
      </ResponsiveContainer>
    </Box>
  );
};

export default BaseChart;
