import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  radii: {
    sm: '6px',      // Small radius
    md: '12px',      // Medium radius
    lg: '36px',     // Large radius
    xl: '48px',     // Extra large radius
    '2xl': '60px',  // 2x Extra large radius
    '3xl': '72px',  // 3x Extra large radius
    'full': '9999px', // Full radius (circular)
  },
  colors: {
    gray: {
      50: '#FAF9F7'
    }
  },
  padding: [
    6,  // TBD
    11, // TBD
    22,
    33, // TBD
    44, // TBD
    60,
    100,
  ]
});

export default theme;
