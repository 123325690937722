import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import BaseChart from './BaseChart';

// Define the data type
interface DataPoint {
  week: string;
  Refactor: number;
  'Unit tests': number;
  Bugs: number;
  Documentation: number;
  Others: number;
}

// Sample data
const data: DataPoint[] = [
  { week: "Week 1", Refactor: 5, 'Unit tests': 2, Bugs: 3, Documentation: 4, Others: 1 },
  { week: "Week 2", Refactor: 6, 'Unit tests': 3, Bugs: 2, Documentation: 5, Others: 4 },
  { week: "Week 3", Refactor: 7, 'Unit tests': 1, Bugs: 4, Documentation: 2, Others: 3 },
  { week: "Week 4", Refactor: 8, 'Unit tests': 4, Bugs: 3, Documentation: 6, Others: 2 },
  { week: "Week 5", Refactor: 9, 'Unit tests': 5, Bugs: 1, Documentation: 7, Others: 3 },
];

const WeeklyPerformanceBarChart: React.FC = () => {
  return (
    <BaseChart heading="Weekly Performance by Task Type">
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Refactor" fill="#8F9CFB" />
        <Bar dataKey="Unit tests" fill="#FFC300" />
        <Bar dataKey="Bugs" fill="#455C9F" />
        <Bar dataKey="Documentation" fill="#A8DFFE" />
        <Bar dataKey="Others" fill="#E6E6E6" />
      </BarChart>
    </BaseChart>
  );
};

export default WeeklyPerformanceBarChart;
