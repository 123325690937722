import React from 'react';
import './App.css';
import Navbar from './Navbar';
import WeeklyPerformanceBarChart from './charts/WeeklyPerformanceBarChart';
import { Box } from '@chakra-ui/react';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Box
        minH="100vh"
        bg="gray.50"
        p={4}
      >
        <WeeklyPerformanceBarChart />
      </Box>
    </div>
  );
}

export default App;
