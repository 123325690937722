import React from 'react';
import logo from './static/maxium-ai-beta-logo.png';
import { Box, Flex, Link, Spacer, HStack, Image } from '@chakra-ui/react';

const MAXIUM_URL = "https://www.maxium.ai/";

const Navbar: React.FC = () => {
  return (
    <Box bg="white" color="black" p={3}>
      <Flex align="center">
        <Box width={200}>
          <Image src={logo} alt='Maxium AI Beta' />
        </Box>
        <Spacer />
        <HStack spacing={4}>
          <Link href={MAXIUM_URL} color="white" _hover={{ textDecoration: 'underline' }}>
            Home
          </Link>
          <Link href={MAXIUM_URL + "#about"} color="white" _hover={{ textDecoration: 'underline' }}>
            About
          </Link>
          <Link href={MAXIUM_URL + "#contact"} color="white" _hover={{ textDecoration: 'underline' }}>
            Contact
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Navbar;
